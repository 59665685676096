<script>
import Layout from "@/router/layouts/auth";
import { mapState, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import Languages from "@/components/widgets/languages";
import Password from "@/components/widgets/input-password";

export default {
  locales: {
    pt: {
      CHANGE: "ALTERAR",
      Username: "Usuário",
      "Username is required.": "Usuário é obrigatório",
      Password: "Senha",
      "Log In": "Entrar",
      "The username and/or password is incorrect.":
        "O usuário e/ou a senha estão incorretos.",
      "The login was successful.": "O login foi efetuado com sucesso.",
      "Forgot your password?": "Esqueceu sua senha?",
      "Don't have an account?": "Não possui uma conta?",
      "Signup now": "Cadastre-se",
    },
    es: {
      CHANGE: "CAMBIAR",
      Username: "Nombre de usuario",
      "Username is required.": "Se requiere nombre de usuario",
      Password: "Contraseña",
      "Log In": "Iniciar sesión",
      "The username and/or password is incorrect.":
        "El nombre de usuario y/o contraseña son incorrectos.",
      "The login was successful.": "El inicio de sesión fue exitoso.",
      "Forgot your password?": "¿Olvidaste tu contraseña?",
      "Don't have an account?": "¿No tienes una cuenta?",
      "Signup now": "Regístrate ahora",
    },
  },
  components: {
    Layout,
    Languages,
    Password,
  },
  data() {
    return {
      language: "en",

      username: "",
      password: "",
      loading: false,
      submitted: false,

      user: {
        name: "",
        username: "",
        avatar: "",
      },
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      alert: (state) => state.alert,
    }),
  },
  validations: {
    username: { required },
    password: { required, minLength: minLength(6) },
  },
  created() {
    this.logout();
  },
  methods: {
    ...mapActions("account", ["login", "logout"]),
    handleSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const { username, password } = this;
        if (username && password) {
          this.alert.type = "";
          this.alert.message = "";
          this.loading = true;
          this.$v.$reset();
          this.login({ username, password });
        }
      }
    },
    clearUser() {
      this.username = "";
      this.user.name = "";
      this.user.username = "";
      this.user.avatar = "";
    },
  },
  mounted() {
    if (this.$store.state.account.user) {
      this.username = this.$store.state.account.user.username;
      this.user.name = this.$store.state.account.user.name;
      this.user.username = this.$store.state.account.user.username;
      this.user.avatar = this.$store.state.account.user.avatar;
    }
  },
  watch: {
    "alert.type": function (alert) {
      if (alert == "alert-danger") {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div id="auth" class="d-flex">
      <div class="login-content">
        <div class="pt-5 pb-3 text-center">
          <router-link class="align-middle" tag="a" to="/">
            <img class="w-100" style="max-width: 200px" src="@/assets/images/logo.png" />
          </router-link>
        </div>
        <div class="py-5">
          <b-form @submit.prevent="handleSubmit">
            <div v-if="alert.message" :class="'alert ' + alert.type">
              {{ t(alert.message) }}
            </div>
            <div v-if="user.username" class="d-flex mb-4">
              <div class="mr-2 align-self-center">
                <img v-if="user.avatar" class="rounded-circle header-profile-user" :src="user.avatar" alt="" />
                <img v-else class="rounded-circle header-profile-user" :src="'https://ui-avatars.com/api/?background=000001&color=fff&name=' +
                  user.name
                  " alt="" />
              </div>
              <div class="flex-fill align-self-center">
                <span class="text-muted text-uppercase">{{
                  user.username
                  }}</span>
              </div>
              <div class="text-right align-self-center">
                <a class="btn btn-outline-secondary btn-sm" v-on:click="clearUser()">
                  {{ t("CHANGE") }}
                </a>
              </div>
            </div>
            <b-form-group v-else id="input-group-1" :label="t('Username')" label-for="input-1">
              <b-form-input id="input-1" v-model="username" type="text" :class="{ 'is-invalid': submitted && $v.username.$error }" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"></b-form-input>
              <div v-if="submitted && !$v.username.required" class="invalid-feedback">
                {{ t("Username is required.") }}
              </div>
            </b-form-group>
            <b-form-group id="input-group-2" :label="t('Password')" label-for="input-2">
              <Password v-model="password" :v="$v.password"></Password>
            </b-form-group>
            <div class="mt-4">
              <b-button :disabled="loading == true" type="submit" variant="default" class="btn-lg btn-block">
                {{ t("Log In") }}
                <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
              </b-button>
            </div>
            <div class="mt-4 text-center">
              <router-link tag="a" to="/password-forgot" class="text-muted">
                <i class="mdi mdi-lock mr-1"></i>
                {{ t("Forgot your password?") }}
              </router-link>
            </div>
          </b-form>
          <div class="mt-4 text-center">
            <p class="text-dark">
              {{ t("Don't have an account?") }}
              <router-link tag="a" to="/register" class="font-weight-medium text-muted">{{ t("Signup now") }}</router-link>
            </p>
            <Languages type="dropup"></Languages>
            <hr class="d-none mx-5" />
            <p class="d-none font-size-12 text-uppercase text-muted">
              <a class="text-muted font-size-11" target="_blank" href="https://m2n.com.br/?utm_source=ismam.com.br&utm_medium=link&utm_campaign=logo">
                TECNOLOGIA POR<br />
                <img alt="M2N" style="width: 50px" src="@/assets/images/logo-m2n.svg" />
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="flex-fill login-image d-none d-lg-block"></div>
    </div>
  </Layout>
</template>